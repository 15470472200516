<template>
  <v-app>
    <enduser-menu />

    <kdw-Statusbar-card
      ref="kdwsnackbar"
    />

    <common-view />

    <common-footer />

    <kdw-Cookiebar-card
      v-model="cookieUpdate"
    />
  </v-app>
</template>

<script>
  export default {
    name: 'EnduserLayout',

    components: {
      EnduserMenu: () => import('@/layouts/enduser/Menu'),
      CommonFooter: () => import('@/layouts/Footer'),
      CommonView: () => import('@/layouts/View'),
    },

    data: () => {
      return { cookieUpdate: false }
    },

    mounted () {
      this.$root.kdwsnackbar = this.$refs.kdwsnackbar
    },
  }
</script>
